import React, { useState } from 'react';
import { graphql, Link as GatsbyLink, navigate } from 'gatsby';

import Layout from '../components/layout';
import Wizard from '../components/wizard/Wizard';
import DonationCauseStep from '../components/card-configuration/steps/DonationCauseStep';
import DonationAmountStep from '../components/card-configuration/steps/DonationAmountStep';
import CardStep from '../components/card-configuration/steps/CardStep';
import DonorInfoStep from '../components/card-configuration/steps/DonorInfoStep';
import { CardConfigModel } from '@dobredary-pl/card-config';
import { CardConfigSchema } from '../components/card-configuration/CardConfigSchema';
import axios from 'axios';
import { Snackbar } from '@mui/material';
import { Alert } from '@mui/material';
import SEO from '../components/SEO';

interface CardConfigurationProps {
  data: any;
  pageContext: string;
}

const CardConfiguration = ({
  data: { wpCard },
  pageContext,
}: CardConfigurationProps) => {
  const [error, setError] = useState(false);
  const [validationError, setValidationError] = useState(false);

  const sendForm = async (cardConfig: CardConfigModel): Promise<void> => {
    setError(false);
    try {
      const result = await axios.post<{ redirectUrl: string }>(
        `${process.env.API_URL}/order`,
        {
          cardConfiguration: { cardId: wpCard.id, ...cardConfig },
        },
        {
          headers: {
            Referer: 'https://dobredary.pl'
          }
        }
      );
    
      if (result?.data?.redirectUrl) {
        window.location.href = result.data.redirectUrl;
      } else {
        throw new Error('No redirect url found');
      }
    } catch (error) {
      setError(true);
    }
    
  };

  const handleBack = () => {
    navigate('/');
  };

  const hideError = (event, reason?) => {
    if (reason === 'clickaway') {
      return;
    }

    setError(false);
  };

  const stepsConfig = [
    {
      key: 'donationCause',
      prompt: 'Wybierz cel',
    },
    {
      key: 'donationAmount',
      prompt: 'Wybierz kwotę darowizny',
    },
    {
      key: 'cardConfig',
      prompt: 'Wypełnij kartkę',
    },
    {
      key: 'donorInfo',
      prompt: 'Podaj swoje dane',
    },
  ];

  const initialCardConfigValues: CardConfigModel = {
    cardId: wpCard.id,
    cardConfig: {
      cardHeader: '',
      cardText: '',
      signature: '',
      showCause: false,
    },
    donorInfo: {
      name: '',
      mail: '',
      allowNewsletter: false,
    },
    donationAmount: {
      selectedItemId: '',
      amount: null,
    },
    donationCause: {
      selectedItemId: '',
      selectedItemText: '',
    },
  };

  return (
    <Layout customSEO>
      <SEO title={wpCard.title} />
      <Wizard
        stepsConfig={stepsConfig}
        initialValues={initialCardConfigValues}
        handleBack={handleBack}
        handleSubmit={sendForm}
        validationSchema={CardConfigSchema}
        validationError={validationError}
      >
        <DonationCauseStep />
        <DonationAmountStep />
        <CardStep card={wpCard} setValidationError={setValidationError} />
        <DonorInfoStep />
      </Wizard>
      <Snackbar
        open={error}
        autoHideDuration={5000}
        onClose={hideError}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={hideError} severity="error">
          Coś poszło nie tak! Spróbuj ponownie później.
        </Alert>
      </Snackbar>
    </Layout>
  );
};

export default CardConfiguration;

export const query = graphql`
  query CardConfigQuery($id: String!) {
    wpCard(id: { eq: $id }) {
      id
      title
    }
  }
`;
