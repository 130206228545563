import { CardGiftModel, CardManagementModel } from '@dobredary-pl/card-config';
import { Button, Grid, IconButton } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useStaticQuery, graphql, navigate } from 'gatsby';
import styled from "styled-components"

interface SendPdfProps {
  uuid: CardManagementModel['uuid'];
  back: () => void;
}

const Spacer = styled.div`
  margin-bottom: 1em;
`;

const SendPdf = ({ uuid, back }: SendPdfProps) => {  
  const [card, setCard] = useState<CardGiftModel | null>(null);
  const [imageUri, setImageUri] = useState<string | null>(null);
  const data = useStaticQuery(graphql`
    query CardImagesQuery {
      allWpCard {
        edges {
          node {
            id
            title
            featuredImage {
              node {
                sourceUrl
              }
            }
          }
        }
      }
    }
  `);

  useEffect(() => {
    axios.get<{ result: CardGiftModel }>(`${process.env.API_URL}/gift/${uuid}`)
      .then(({ data: { result: card } }) => {
        setCard(card);
        const image = data.allWpCard.edges.find(edge => edge.node.id === card.cardId)?.node;
        setImageUri(image?.featuredImage.node.sourceUrl);
      })
      .catch(() => navigate('/404'));
  }, [data, uuid]);

  const downloadPdf = async (card) => {
    if (!card || !imageUri) return;
    const url = `https://pdf.dobredary.pl/order/${card.uuid}?image_url=${imageUri}`;
    window.open(url, "_blank", "noreferrer");
  };

  return (
    <>
      <IconButton
        color="primary"
        aria-label="back"
        onClick={() => back()}
        size="large"
      >
        <ArrowBack fontSize="small" />
      </IconButton>
      <br />
      <Spacer>
        Pobierz kartkę w PDF i prześlij obdarowanej osobie:
      </Spacer>
      <Spacer>
        <form noValidate autoComplete="off">
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={4}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => downloadPdf(card)}
              >
                Pobierz
              </Button>
            </Grid>
          </Grid>
        </form>
      </Spacer>
    </>
  );
};

export default SendPdf;