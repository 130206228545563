import React, { useEffect } from 'react';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
} from '@mui/material';
import { useFormikContext } from 'formik';
import { CardConfigModel, CardGiftModel } from '@dobredary-pl/card-config';
import CardImagePreview from '../../card-details/CardImagePreview';

type CardStepProps = {
  card: { id: string };
  setValidationError: (hasError: boolean) => void;
};

const hasEmoji = (text: string) => {
  const emojiRegex = /[\p{Emoji_Presentation}\p{Extended_Pictographic}]/gu;
  return emojiRegex.test(text);
};

const CardStep = ({ card, setValidationError }: CardStepProps) => {
  const {
    handleBlur,
    handleChange,
    setFieldError,
    values: { cardConfig, donationCause },
    errors: { cardConfig: cardConfigErrors },
    touched: { cardConfig: cardConfigTouched },
  } = useFormikContext<CardConfigModel>();

  useEffect(() => {
    const hasAnyEmoji = 
      hasEmoji(cardConfig.cardHeader) || 
      hasEmoji(cardConfig.cardText) || 
      hasEmoji(cardConfig.signature);
    setValidationError(hasAnyEmoji);
  }, [cardConfig.cardHeader, cardConfig.cardText, cardConfig.signature, setValidationError]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (hasEmoji(value)) {
      setFieldError(name, 'Emotikony nie są dozwolone w tym polu');
    }
    handleChange(e);
  };

  const cardPreview: CardGiftModel = {
    uuid: null,
    cardId: card.id,
    cardConfig,
    donationCause,
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm md>
        <CardImagePreview cardData={cardPreview}></CardImagePreview>
      </Grid>
      <Grid item xs={12} sm={8} md={7}>
        <Box my={4}>
          <TextField
            fullWidth
            name="cardConfig.cardHeader"
            label="Treść nagłówka"
            placeholder="Wpisz własny nagłówek kartki"
            value={cardConfig.cardHeader}
            onChange={handleInputChange}
            onBlur={handleBlur}
            error={
              (cardConfigTouched?.cardHeader &&
                Boolean(cardConfigErrors?.cardHeader)) ||
              hasEmoji(cardConfig.cardHeader)
            }
            helperText={
              (cardConfigTouched?.cardHeader && cardConfigErrors?.cardHeader) ||
              (hasEmoji(cardConfig.cardHeader) &&
                'Emotikony nie są dozwolone w tym polu')
            }
          />
        </Box>
        <Box my={4}>
          <TextField
            fullWidth
            multiline
            rows={4}
            name="cardConfig.cardText"
            label="Treść życzeń"
            placeholder="Napisz własny tekst życzeń"
            value={cardConfig.cardText}
            onChange={handleInputChange}
            onBlur={handleBlur}
            error={
              (cardConfigTouched?.cardText && Boolean(cardConfigErrors?.cardText)) ||
              hasEmoji(cardConfig.cardText)
            }
            helperText={
              (cardConfigTouched?.cardText && cardConfigErrors?.cardText) ||
              (hasEmoji(cardConfig.cardText) &&
                'Emotikony nie są dozwolone w tym polu')
            }
          />
        </Box>
        <Box my={4}>
          <TextField
            fullWidth
            name="cardConfig.signature"
            label="Podpis"
            placeholder="Wpisz własne imię"
            value={cardConfig.signature}
            onChange={handleInputChange}
            onBlur={handleBlur}
            error={
              (cardConfigTouched?.signature &&
                Boolean(cardConfigErrors?.signature)) ||
              hasEmoji(cardConfig.signature)
            }
            helperText={
              (cardConfigTouched?.signature && cardConfigErrors?.signature) ||
              (hasEmoji(cardConfig.signature) &&
                'Emotikony nie są dozwolone w tym polu')
            }
          />
        </Box>
        <Box my={2}>
          <FormControlLabel
            control={
              <Checkbox
                checked={cardConfig.showCause}
                onChange={handleChange}
                onBlur={handleBlur}
                name="cardConfig.showCause"
                color="primary"
              />
            }
            label="Pokaż na kartce cel, na który została przeznaczona darowizna"
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default CardStep;